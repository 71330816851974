<template>
  <div class="wrap">
    <div class="banner" :style="{backgroundImage: 'url(' + imgUrl.banner + ')'}">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">CDR数字病区系统支持系统与大数据中心</p>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>产品<em>介绍</em></h2>
        <div class="details">
          <!-- <div class="img">
            <img src="../../assets/images/emr-text.png"
                 alt="">
          </div> -->
          <div class="text bg">
            <p>用于支持数字病区各软件系统和硬件系统的正常运行，并负责CDR临床大数据AI存储和交换</p>
            <p>ESB 智能数据交换系统</p>
            <p>超大规模数据存储系统</p>
            <p>全面支持CDR数字病区各中心系统与子系统功能运行</p>
            <p>IBM超大规模主服务器配置</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="content bg">
      <div class="en t1"></div>
      <div class="inner product">
        <h2>产品<em>特征</em></h2>
        <div class="list">
          <ul>
            <li>
              <em class="p1"></em>
              <h3>病例跟踪</h3>
            </li>
            <li>
              <em class="p2"></em>
              <h3>医护沟通</h3>
            </li>
            <li>
              <em class="p3"></em>
              <h3>远程会议</h3>
            </li>
            <li>
              <em class="p4"></em>
              <h3>病理分析</h3>
            </li>
            <li>
              <em class="p5"></em>
              <h3>学术支持</h3>
            </li>
            <li>
              <em class="p6"></em>
              <h3>临床医疗</h3>
            </li>
            <li>
              <em class="p7"></em>
              <h3>远程医疗</h3>
            </li>
            <li>
              <em class="p8"></em>
              <h3>医学影像</h3>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import getAliyunImg from '../../utils/aliyun'

export default {
  name: 'data',
  created () {
    this.imgUrl.banner = getAliyunImg.signatureUrl('ytwd/gw/banner3.png')
  },
  data () {
    return {
      imgUrl: {
        banner: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./css/style.scss";
.banner {
  // background-image: url("../../assets/images/banner3.png");
  .icon {
    background-image: url("../../assets/images/s12.png");
  }
}
.content {
  .product .details {
    align-items: center;
    justify-content: center;
    .text {
      padding: 75px 30px;
      display: flex;
      height: auto;
      flex-direction: column;
      // align-self: flex-start;
      &.bg {
        background-size: 210px 207px;
      }
      p {
        text-indent: 0;
      }
    }
    .img {
      // height: 535px;
      background: none;
    }
  }
}
</style>
